:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono', 'Roboto Mono',
    'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro', 'Fira Mono', 'Droid Sans Mono',
    'Courier New', monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

.row-red {
  background: linear-gradient(to right, #e57374 0%, #202020 18%, rgba(0, 0, 0, 0) 100%) !important;
}

.row-red-light {
  background: linear-gradient(
    to right,
    #e57374 0%,
    #fff 18%,
    rgba(255, 255, 255, 0) 100%
  ) !important;
}

.row-blue {
  background: linear-gradient(to right, #8187c7 0%, #8187c7 10%, rgba(0, 0, 0, 0) 100%) !important;
}

.row-yellow {
  background: linear-gradient(to right, #c0c000 0%, #c0c000 10%, rgba(0, 0, 0, 0) 100%) !important;
}

.row-green-light {
  background: linear-gradient(
    to right,
    #81c784 0%,
    #202020 18%,
    rgba(255, 255, 255, 0) 100%
  ) !important;
}

.row-green {
  background: linear-gradient(to right, #81c784 0%, #fff 18%, rgba(0, 0, 0, 0) 100%) !important;
}

.row-rush-light {
  background: linear-gradient(
    to right,
    #ff0000 0%,
    #fff 18%,
    rgba(255, 255, 255, 0) 100%
  ) !important;
}

.row-shop-light {
  background-color: #00c003 !important; /* Use !important to override default DataGrid styles */
  color: white;
}

.row-prod-light {
  background-color: #ff7474 !important; /* Use !important to override default DataGrid styles */
}

.row-dupe-light {
  background-color: #feff41 !important; /* Use !important to override default DataGrid styles */
}

.row-done-light {
  background-color: #ff3aa6 !important; /* Use !important to override default DataGrid styles */
}

.row-rush {
  background: linear-gradient(
    to right,
    #8b0000 0%,
    #202020 18%,
    rgba(18, 18, 18, 0) 100%
  ) !important;
}

.row-shop {
  background-color: #556b2f !important; /* Dark Olive Green */
}

.row-prod {
  background-color: #cd5c5c !important; /* Indian Red */
}

.row-dupe {
  background-color: #ffd700 !important; /* Gold */
}

.row-done {
  background-color: #8a2be2 !important; /* Blue Violet */
}

.row-red-faded {
  background: linear-gradient(to right, #bd5557 0%, #bd5557 10%, rgba(0, 0, 0, 0) 100%) !important;
}

.row-yellow-faded {
  background: linear-gradient(to right, #85853a 0%, #85853a 10%, rgba(0, 0, 0, 0) 100%) !important;
}

.row-green-faded {
  background: linear-gradient(to right, #539b56 0%, #539b56 10%, rgba(0, 0, 0, 0) 100%) !important;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

/* Light mode styles */
.row-even {
  background: rgb(245, 245, 245); /* Light grey for even rows */
}

.row-odd {
  background: #ffffff; /* White for odd rows */
}

.row-odd-red {
  background: linear-gradient(
    to right,
    #e57374 0%,
    rgba(48, 48, 48, 1) 18%,
    rgba(48, 48, 48, 1) 100%
  ) !important;
}

.row-odd-red-light {
  background: linear-gradient(
    to right,
    #e57374 0%,
    rgba(255, 255, 255, 1) 18%,
    rgba(255, 255, 255, 1) 100%
  ) !important;
}

.row-even-red {
  background: linear-gradient(
    to right,
    #e57374 0%,
    rgba(66, 66, 66, 1) 18%,
    rgba(66, 66, 66, 1) 100%
  ) !important;
}

.row-even-red-light {
  background: linear-gradient(
    to right,
    #e57374 0%,
    rgba(245, 245, 245, 1) 18%,
    rgba(245, 245, 245, 1) 100%
  ) !important;
}

/* Dark mode styles */
.row-even-dark {
  background: rgb(66, 66, 66); /* Dark grey for even rows */
}

.row-odd-dark {
  background: rgb(48, 48, 48); /* Slightly darker grey for odd rows */
}

.wrap-text {
  white-space: normal !important;
  word-break: break-word;
}

.MuiPickersTimePickerToolbar-hourMinute,
.MuiPickersClock {
  overflow: hidden !important; /* Force no scrollbars */
}

.noscrollbar::-webkit-scrollbar {
  overflow: hidden;
  display: none;
}

@media print {
  .print-container {
    transform: scale(0.5);
    width: 4in;
    height: 8in;
  }
}

.duplicate-level-1 {
  background-color: #f8e8e0; /* Light Red - WO */
}
.duplicate-level-2 {
  background-color: #d4edda; /* Light Green - WO & Door Color */
}
.duplicate-level-3 {
  background-color: #cce5ff; /* Light Blue - WO & Species & Door Color */
}
.duplicate-level-4 {
  background-color: #ffddc1; /* Light Orange - WO & Door Style & Species & Door Color */
}
.duplicate-level-5 {
  background-color: #ff6b6b; /* Strong Red - WO & Door Style & Species & Door Color & Door Qty */
}

.label {
  font-family: 'Roboto';
}
